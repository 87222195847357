import ReactDOM from 'react-dom';
import App from '../components/App';

import theme from "../theme";

export class ReturnsComponentElement extends HTMLElement {
  connectedCallback() {

    ReactDOM.render(<App theme={theme} />, this);
  }
}