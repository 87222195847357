import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { AxiosContext } from '../context/AxiosContext';
import { useParams } from 'react-router-dom';

export const OrderReturn = ({ orderInfo }) => {
  const [data, setData] = useState();
  const { authAxios } = useContext(AxiosContext);
  const { orderNumber } = useParams();

  useEffect(() => {
    if (orderNumber) {
      authAxios({
        url: `/order/returns/GetAvailableItems`,
      }).then((response) => {
        if (response.data) {
          setData(response.data);
        } else {
          console.log('Failed to get data');
        }
      });
    }
  }, [authAxios, orderNumber]);

  const availableItems = data?.availableForReturn?.items;
  const unavailableItems = data?.unavailableForReturn?.items;

  return (
    <div>
      <Button component={Link} to="/" variant="contained" color="primary">
        Back
      </Button>
      <hr />
      <div>
        <h1>Available for returns</h1>
        <List items={data?.availableForReturn?.items} />
        <h1>Unavailable for returns</h1>
        <List items={data?.unavailableForReturn?.items} />
      </div>
    </div>
  );
};

const List = ({ items }) => {
  return (
    <ul>
      {items?.map((item) => (
        <li>
          {item.productDisplayName} | Price: {item.productPrice} | Quantity {item.availableQuantity} |
          <Button color="secondary">Return</Button>
        </li>
      ))}
    </ul>
  );
};
