import './App.css';
import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core';
import { OrderReturn } from './OrderReturnComponent';
import { OrderInfoComponent } from './OrderInfoComponent';
import { AuthProvider } from '../context/AuthContext';
import { AxiosProvider } from '../context/AxiosContext';
import { OrderToken } from './OrderTokenComponent';

export default function App({ theme }) {
  
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <AxiosProvider>
          <Router>
            <div>
              <Switch>
                <Route exact path="/">
                  <OrderInfoComponent />
                </Route>
                <Route path="/return/:orderNumber">
                  <OrderReturn />
                </Route>
                <Route path="/token/:orderNumber/:token">
                  <OrderToken  />
                </Route>
              </Switch>
            </div>
          </Router>
        </AxiosProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
