import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useHistory } from 'react-router-dom';

export const OrderToken = ({ orderInfo }) => {
  const { orderNumber, token } = useParams();
  const authContext = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    authContext.setAuthState({
      accessToken: token,
      authenticated: true,
    });

    history.push(`/return/${orderNumber}`);
  }, [authContext, history, orderNumber, token]);

  return <div>{orderNumber}</div>;
};
