import React, { createContext, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';

const AxiosContext = createContext();
const { Provider } = AxiosContext;

const AxiosProvider = ({ children }) => {
  const authContext = useContext(AuthContext);

  const authAxios = axios.create({
    baseURL: 'https://occdevorderreturnsapi.azurewebsites.net/api',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const publicAxios = axios.create({
    baseURL: 'https://occdevorderreturnsapi.azurewebsites.net/api',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  authAxios.interceptors.request.use(
    (config) => {
      if (!config.headers['x-access-token']) {
        config.headers['x-access-token'] = `${authContext.getAccessToken()}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return (
    <Provider
      value={{
        authAxios,
        publicAxios,
      }}
    >
      {children}
    </Provider>
  );
};

export { AxiosContext, AxiosProvider };
