import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import { AuthContext } from '../context/AuthContext';
import { AxiosContext } from '../context/AxiosContext';

export function OrderInfoComponent() {
  const authContext = useContext(AuthContext);
  const { publicAxios } = useContext(AxiosContext);
  const [orderInfo, setOrderInfo] = useState({
    orderNumber: '',
    email: '',
  });

  const handleOrderInfoChange = (event) => {
    setOrderInfo({ ...orderInfo, [event.target.name]: event.target.value });
  };

  const history = useHistory();
  const handleClick = () => {
    publicAxios
      .post(`/token/${orderInfo.orderNumber}?email=${orderInfo.email}`)
      .then((response) => {
        authContext.setAuthState({
          accessToken: response.data.token,
          authenticated: true,
        });
        history.push(`/return/${orderInfo.orderNumber}`);
      });
  };

  return (
    <div>
      <Grid container>
        <Grid item>
          <TextField name="orderNumber" label="Order Number" value={orderInfo.orderNumber} onChange={handleOrderInfoChange} />
          <TextField name="email" label="Email" value={orderInfo.email} onChange={handleOrderInfoChange} />
        </Grid>
        <Grid item alignItems="stretch" style={{ display: 'flex' }}>
          <Box sx={{ m: '16px' }}>
            <Button variant="contained" color="primary" onClick={handleClick} disabled={!orderInfo.orderNumber}>
              Find
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
